/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function DescriptionCard(props) {
  const { imageSrc, text, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Image: {},
        "Text Description": {},
        "Frame Text": {},
        DescriptionCard: {},
      },
      variantValues: { size: "big" },
    },
    {
      overrides: {
        Image: {},
        "Text Description": {
          fontSize: "18px",
          width: "unset",
          alignSelf: "stretch",
        },
        "Frame Text": {
          width: "566px",
          height: "534px",
          padding: "50px 30px 50px 30px",
        },
        DescriptionCard: { width: "566px", height: "534px" },
      },
      variantValues: { size: "default" },
    },
    {
      overrides: {
        Image: {},
        "Text Description": {
          fontSize: "14px",
          width: "359px",
          height: "unset",
        },
        "Frame Text": {
          width: "412px",
          height: "389px",
          padding: "30px 0px 30px 0px",
        },
        DescriptionCard: { width: "412px", height: "389px" },
      },
      variantValues: { size: "medium" },
    },
    {
      overrides: {
        Image: {},
        "Text Description": {
          fontSize: "12px",
          lineHeight: "20px",
          width: "unset",
          height: "unset",
          alignSelf: "stretch",
        },
        "Frame Text": {
          width: "342px",
          height: "323px",
          padding: "30px 20px 30px 20px",
        },
        DescriptionCard: { width: "342px", height: "323px" },
      },
      variantValues: { size: "small" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="700px"
      height="660px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "DescriptionCard")}
      {...rest}
    >
      <Image
        width="100%"
        height="100%"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src={imageSrc}
        {...getOverrideProps(overrides, "Image")}
      ></Image>
      <Flex
        gap="0"
        direction="column"
        width="700px"
        height="660px"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="50px 0px 50px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame Text")}
      >
        <Text
          fontFamily="Poppins"
          fontSize="20px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.85px"
          width="530px"
          height="177px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={text}
          {...getOverrideProps(overrides, "Text Description")}
        ></Text>
      </Flex>
    </View>
  );
}
