import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/TransparentButton.css';

const TransparentButton = ({ size, children, onClick }) => {

    const buttonSizeClass = size === 'default' ? 'transparent-button-default' : 'transparent-button-small';

    return (
        <button className={"transparent-button" + " " + buttonSizeClass} onClick={onClick}>
            {children}
        </button>
    );
};

TransparentButton.propTypes = {
    size: PropTypes.oneOf(['default', 'small']),
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired 
};

TransparentButton.defaultProps = {
    size: 'default' 
};

export default TransparentButton;
