/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
import InfoCard from "./InfoCard";
import Logo from "./Logo";
export default function BlockWhyArenaAdditional(props) {
  const { imageUsersSrc, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Text Heading": {},
        "Image Users": {},
        InfoCard1: {},
        InfoCard2: {},
        InfoCard3: {},
        "Column 1": {},
        InfoCard4: {},
        Logo: {},
        "Frame Logo": {},
        InfoCard6: {},
        "Column 2": {},
        InfoCard7: {},
        InfoCard8: {},
        InfoCard9: {},
        "Column 3": {},
        "Frame Cards": {},
        BlockWhyArenaAdditional: {},
      },
      variantValues: { size: "small" },
    },
    {
      overrides: {
        "Text Heading": { fontSize: "52px" },
        "Image Users": { height: "400px" },
        InfoCard1: {},
        InfoCard2: {},
        InfoCard3: {},
        "Column 1": {},
        InfoCard4: {},
        Logo: {},
        "Frame Logo": { height: "240px" },
        InfoCard6: {},
        "Column 2": {},
        InfoCard7: {},
        InfoCard8: {},
        InfoCard9: {},
        "Column 3": {},
        "Frame Cards": { direction: "row", padding: "25px 25px 25px 25px" },
        BlockWhyArenaAdditional: {
          gap: "25px",
          padding: "25px 25px 25px 25px",
        },
      },
      variantValues: { size: "default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="20px 10px 20px 10px"
      backgroundColor="rgba(31,29,29,1)"
      display="flex"
      {...getOverrideProps(overrides, "BlockWhyArenaAdditional")}
      {...rest}
    >
      <Text
        fontFamily="Poppins"
        fontSize="24px"
        fontWeight="600"
        color="rgba(255,255,255,1)"
        lineHeight="62px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="-1.85px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Why ARENA"
        {...getOverrideProps(overrides, "Text Heading")}
      ></Text>
      <Image
        width="unset"
        height="114px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        objectFit="contain"
        src={imageUsersSrc}
        {...getOverrideProps(overrides, "Image Users")}
      ></Image>
      <Flex
        gap="25px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="50px 0px 50px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame Cards")}
      >
        <Flex
          gap="25px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Column 1")}
        >
          <InfoCard
            width="340px"
            height="222px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "InfoCard1")}
          ></InfoCard>
          <InfoCard
            width="340px"
            height="222px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "InfoCard2")}
          ></InfoCard>
          <InfoCard
            width="340px"
            height="222px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "InfoCard3")}
          ></InfoCard>
        </Flex>
        <Flex
          gap="25px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Column 2")}
        >
          <InfoCard
            width="340px"
            height="222px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "InfoCard4")}
          ></InfoCard>
          <Flex
            gap="0"
            direction="column"
            width="368px"
            height="110px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame Logo")}
          >
            <Logo
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              {...getOverrideProps(overrides, "Logo")}
            ></Logo>
          </Flex>
          <InfoCard
            width="340px"
            height="222px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "InfoCard6")}
          ></InfoCard>
        </Flex>
        <Flex
          gap="25px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Column 3")}
        >
          <InfoCard
            width="340px"
            height="222px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "InfoCard7")}
          ></InfoCard>
          <InfoCard
            width="340px"
            height="222px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "InfoCard8")}
          ></InfoCard>
          <InfoCard
            width="340px"
            height="222px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "InfoCard9")}
          ></InfoCard>
        </Flex>
      </Flex>
    </Flex>
  );
}
