/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
import UserTitleBlack from "./UserTitleBlack";
import RatingBlack from "./RatingBlack";
export default function UserFeedbackCard(props) {
  const { imageSrc, feedbackText, overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="375px"
      height="742px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="30px 30px 30px 30px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "UserFeedbackCard")}
      {...rest}
    >
      <Image
        width="316px"
        height="312px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(189,79,203,1)"
        borderRadius="20px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src={imageSrc}
        {...getOverrideProps(overrides, "Image User")}
      ></Image>
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame Feedback")}
      >
        <Text
          fontFamily="Poppins"
          fontSize="18px"
          fontWeight="500"
          color="rgba(9,9,20,1)"
          lineHeight="28px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="322px"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={feedbackText}
          {...getOverrideProps(overrides, "Text Feedback")}
        ></Text>
        <UserTitleBlack
          display="flex"
          gap="0"
          direction="column"
          width="185px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "UserTitleBlack")}
        ></UserTitleBlack>
      </Flex>
      <RatingBlack
        display="flex"
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 10px 0px 10px"
        {...getOverrideProps(overrides, "RatingBlack")}
      ></RatingBlack>
    </Flex>
  );
}
