import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () =>
    <div style={styles.container}>
        <h1 style={styles.heading}>Oops! 404 - Page Not Found</h1>
        <p style={styles.paragraph}>Looks like you've stumbled upon a page that doesn't exist.</p>
        <p style={styles.paragraph}>But don't worry, we'll help you find your way back to the <Link to="/" style={styles.link}>Home</Link> page.</p>
    </div>;

const styles = {
    container: {
        textAlign: 'center',
        marginTop: '50px',
        fontFamily: 'Montserrat, sans-serif',
    },
    heading: {
        fontSize: '36px',
        marginBottom: '20px',
        fontFamily: 'Montserrat, sans-serif',
    },
    paragraph: {
        fontSize: '18px',
        marginBottom: '10px',
        fontFamily: 'Montserrat, sans-serif',
    },
    link: {
        color: 'BD4FCB',
        textDecoration: 'none',
        fontSize: '24px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat, sans-serif',
    }
};

export default NotFound;
