import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/SmallTransparentButton.css';

const SmallTransparentButton = ({ size, children, onClick }) => {

    const buttonSizeClass = size === 'default' ? 'small-transparent-button-default' : 'small-transparent-button-small';

    return (
        <button className={"small-transparent-button" + " " + buttonSizeClass} onClick={onClick}>
            {children}
        </button>
    );
};

SmallTransparentButton.propTypes = {
    size: PropTypes.oneOf(['default', 'small']),
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired 
};

SmallTransparentButton.defaultProps = {
    size: 'default' 
};

export default SmallTransparentButton;
