/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import Logo from "./Logo";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function Footer(props) {
  const {
    buttonJoinTheApp,
    buttonJoinAsPerformer,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        Logo: {},
        Text: {},
        Rectangle4392: {},
        "Group Rectangle4391": {},
        Title4393: {},
        ButtonJoinTheApp: {},
        Rectangle4396: {},
        "Group Rectangle4395": {},
        Title4397: {},
        ButtonJoinAsPerformer: {},
        FrameJoinButtons: {},
        Rectangle4401: {},
        "Group Rectangle4400": {},
        ProductTitle: {},
        ButtonProduct: {},
        Rectangle4405: {},
        "Group Rectangle4404": {},
        WhyArenaTitle: {},
        ButtonPerformers: {},
        FrameInfoButtons: {},
        Footer: {},
      },
      variantValues: { size: "small" },
    },
    {
      overrides: {
        Logo: {},
        Text: { fontSize: "40px", lineHeight: "50px" },
        Rectangle4392: {
          width: "300px",
          height: "76px",
          top: "calc(50% - 38px - 0px)",
          left: "calc(50% - 150px - 0px)",
        },
        "Group Rectangle4391": { width: "300px", height: "76px" },
        Title4393: {
          fontSize: "20px",
          fontWeight: "600",
          lineHeight: "24.3799991607666px",
          top: "calc(50% - 18.37px - -4.37px)",
          left: "calc(50% - 104.5px - 2.5px)",
        },
        ButtonJoinTheApp: { width: "300px", height: "76px" },
        Rectangle4396: {
          width: "300px",
          height: "76px",
          top: "calc(50% - 38px - 0px)",
          left: "calc(50% - 150px - 0px)",
        },
        "Group Rectangle4395": { width: "300px", height: "76px" },
        Title4397: {
          fontSize: "20px",
          fontWeight: "600",
          lineHeight: "24.3799991607666px",
          top: "calc(50% - 12px - 2px)",
          left: "calc(50% - 92.5px - -4.5px)",
        },
        ButtonJoinAsPerformer: { width: "300px", height: "76px" },
        FrameJoinButtons: {
          gap: "70px",
          direction: "row",
          alignSelf: "stretch",
        },
        Rectangle4401: { left: "calc(50% - 88px - -74px)" },
        "Group Rectangle4400": {},
        ProductTitle: {
          fontSize: "20px",
          lineHeight: "24.3799991607666px",
          textAlign: "right",
          left: "calc(50% - 61.31px - -5.53px)",
        },
        ButtonProduct: { width: "148px" },
        Rectangle4405: {},
        "Group Rectangle4404": {},
        WhyArenaTitle: {
          fontSize: "20px",
          lineHeight: "24.3799991607666px",
          textAlign: "right",
        },
        ButtonPerformers: {},
        FrameInfoButtons: { gap: "10px", width: "320px" },
        Footer: {
          gap: "45px",
          width: "1544px",
          padding: "30px 50px 30px 50px",
        },
      },
      variantValues: { size: "default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="15px"
      direction="column"
      width="375px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="20px 20px 20px 20px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "Footer")}
      {...rest}
    >
      <Logo
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        size="default"
        {...getOverrideProps(overrides, "Logo")}
      ></Logo>
      <Text
        fontFamily="Jost"
        fontSize="24px"
        fontWeight="500"
        color="rgba(0,0,0,1)"
        lineHeight="30px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.85px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="World's best talent participates in battles versus each other! Support your favorites! Help them win. Become a performer and participate yourself. It's battling platform like you never experienced before!"
        {...getOverrideProps(overrides, "Text")}
      ></Text>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "FrameJoinButtons")}
      >
        <View
          width="291px"
          height="45px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children={buttonJoinTheApp}
          {...getOverrideProps(overrides, "ButtonJoinTheApp")}
        ></View>
        <View
          width="291px"
          height="45px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children={buttonJoinAsPerformer}
          {...getOverrideProps(overrides, "ButtonJoinAsPerformer")}
        ></View>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="372px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "FrameInfoButtons")}
      >
        <View
          width="176px"
          height="50px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ButtonProduct")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="0px"
            height="0px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="100%"
            left="0%"
            right="100%"
            {...getOverrideProps(overrides, "Group Rectangle4400")}
          >
            <View
              width="176px"
              height="50px"
              display="none"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="calc(50% - 25px - -25px)"
              left="calc(50% - 88px - -88px)"
              border="3px SOLID rgba(87,17,112,1)"
              borderRadius="60px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Rectangle4401")}
            ></View>
          </View>
          <Text
            fontFamily="Montserrat"
            fontSize="18px"
            fontWeight="500"
            color="rgba(0,0,0,1)"
            textTransform="capitalize"
            lineHeight="21.941999435424805px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="122.61px"
            height="24.17px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="calc(50% - 12.08px - -2.87px)"
            left="calc(50% - 61.31px - 1.47px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Product"
            {...getOverrideProps(overrides, "ProductTitle")}
          ></Text>
        </View>
        <View
          width="176px"
          height="50px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ButtonPerformers")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="0px"
            height="0px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="100%"
            left="0%"
            right="100%"
            {...getOverrideProps(overrides, "Group Rectangle4404")}
          >
            <View
              width="176px"
              height="50px"
              display="none"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="calc(50% - 25px - -25px)"
              left="calc(50% - 88px - -88px)"
              border="3px SOLID rgba(87,17,112,1)"
              borderRadius="60px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Rectangle4405")}
            ></View>
          </View>
          <Text
            fontFamily="Montserrat"
            fontSize="18px"
            fontWeight="500"
            color="rgba(0,0,0,1)"
            textTransform="capitalize"
            lineHeight="21.941999435424805px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="122.61px"
            height="24.17px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="calc(50% - 12.08px - -2.87px)"
            left="calc(50% - 61.31px - 1.47px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Why Arena"
            {...getOverrideProps(overrides, "WhyArenaTitle")}
          ></Text>
        </View>
      </Flex>
    </Flex>
  );
}
