/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserInfoModel = /* GraphQL */ `
  mutation CreateUserInfoModel(
    $input: CreateUserInfoModelInput!
    $condition: ModelUserInfoModelConditionInput
  ) {
    createUserInfoModel(input: $input, condition: $condition) {
      id
      name
      email
      userType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserInfoModel = /* GraphQL */ `
  mutation UpdateUserInfoModel(
    $input: UpdateUserInfoModelInput!
    $condition: ModelUserInfoModelConditionInput
  ) {
    updateUserInfoModel(input: $input, condition: $condition) {
      id
      name
      email
      userType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserInfoModel = /* GraphQL */ `
  mutation DeleteUserInfoModel(
    $input: DeleteUserInfoModelInput!
    $condition: ModelUserInfoModelConditionInput
  ) {
    deleteUserInfoModel(input: $input, condition: $condition) {
      id
      name
      email
      userType
      createdAt
      updatedAt
      __typename
    }
  }
`;
