/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import UserFeedbackCard from "./UserFeedbackCard";
export default function BlockWhatOurUsersTell(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Text Heading": {},
        UserFeedbackCard6627: {},
        UserFeedbackCard6647: {},
        UserFeedbackCard6667: {},
        "Frame Cards": {},
        BlockWhatOurUsersTell: {},
      },
      variantValues: { size: "small" },
    },
    {
      overrides: {
        "Text Heading": { fontSize: "52px" },
        UserFeedbackCard6627: {},
        UserFeedbackCard6647: {},
        UserFeedbackCard6667: {},
        "Frame Cards": {
          gap: "0",
          direction: "row",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "0px 0px 0px 0px",
        },
        BlockWhatOurUsersTell: {
          gap: "50px",
          justifyContent: "center",
          padding: "50px 50px 50px 50px",
        },
      },
      variantValues: { size: "default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="20px 20px 20px 20px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "BlockWhatOurUsersTell")}
      {...rest}
    >
      <Text
        fontFamily="Poppins"
        fontSize="24px"
        fontWeight="600"
        color="rgba(9,9,20,1)"
        lineHeight="62px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="-1.85px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="What our users say"
        {...getOverrideProps(overrides, "Text Heading")}
      ></Text>
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 20px 0px 20px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Frame Cards")}
      >
        <UserFeedbackCard
          display="flex"
          gap="10px"
          direction="column"
          width="375px"
          height="742px"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="30px 30px 30px 30px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "UserFeedbackCard6627")}
        ></UserFeedbackCard>
        <UserFeedbackCard
          display="flex"
          gap="10px"
          direction="column"
          width="375px"
          height="742px"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="30px 30px 30px 30px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "UserFeedbackCard6647")}
        ></UserFeedbackCard>
        <UserFeedbackCard
          display="flex"
          gap="10px"
          direction="column"
          width="375px"
          height="742px"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="30px 30px 30px 30px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "UserFeedbackCard6667")}
        ></UserFeedbackCard>
      </Flex>
    </Flex>
  );
}
