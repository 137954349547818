/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function BlockJoinArenaForm(props) {
  const {
    imageSrc,
    formPlaceHoder,
    title,
    description,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        image: {},
        "Join ARENA": {},
        desc: {},
        Title: {},
        FormPlaceHoder: {},
        Right: {},
        MainContainer: {},
        BlockJoinArenaForm: {},
      },
      variantValues: { size: "small" },
    },
    {
      overrides: {
        image: {
          width: "504px",
          height: "unset",
          display: "block",
          alignSelf: "stretch",
        },
        "Join ARENA": { fontSize: "52px" },
        desc: {},
        Title: { gap: "10px" },
        FormPlaceHoder: {},
        Right: { gap: "30px", padding: "50px 50px 50px 50px" },
        MainContainer: {
          direction: "row",
          width: "653px",
          height: "unset",
          justifyContent: "flex-end",
          alignSelf: "stretch",
        },
        BlockJoinArenaForm: {
          direction: "row",
          width: "1157px",
          height: "750px",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          backgroundColor: "rgba(0,0,0,1)",
        },
      },
      variantValues: { size: "default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="375px"
      height="800px"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "BlockJoinArenaForm")}
      {...rest}
    >
      <Image
        width="375px"
        height="606px"
        display="none"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        objectFit="contain"
        src={imageSrc}
        {...getOverrideProps(overrides, "image")}
      ></Image>
      <Flex
        gap="0"
        direction="column"
        width="375px"
        height="800px"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "MainContainer")}
      >
        <Flex
          gap="25px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="25px 25px 25px 25px"
          display="flex"
          {...getOverrideProps(overrides, "Right")}
        >
          <Flex
            gap="15px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Title")}
          >
            <Text
              fontFamily="Poppins"
              fontSize="40px"
              fontWeight="600"
              color="rgba(9,9,20,1)"
              lineHeight="62px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="-1.85px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={title}
              {...getOverrideProps(overrides, "Join ARENA")}
            ></Text>
            <Text
              fontFamily="Poppins"
              fontSize="18px"
              fontWeight="400"
              color="rgba(82,82,91,1)"
              lineHeight="25px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={description}
              {...getOverrideProps(overrides, "desc")}
            ></Text>
          </Flex>
          <View
            width="unset"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            children={formPlaceHoder}
            {...getOverrideProps(overrides, "FormPlaceHoder")}
          ></View>
        </Flex>
      </Flex>
    </Flex>
  );
}
