import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/SmallPurpleButton.css';

const SmallPurpleButton = ({ size, children, onClick }) => {

    const buttonSizeClass = size === 'default' ? 'small-purple-button-default' : 'small-purple-button-small';

    return (
        <button className={"small-purple-button" + " " + buttonSizeClass} onClick={onClick}>
            {children}
        </button>
    );
};

SmallPurpleButton.propTypes = {
    size: PropTypes.oneOf(['default', 'small']),
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired 
};

SmallPurpleButton.defaultProps = {
    size: 'default' 
};

export default SmallPurpleButton;
