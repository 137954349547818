/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function BlockArenaDescription(props) {
  const {
    buttonBlankWhite,
    buttonPurple,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        "Text Heading": {},
        "Text Description": {},
        Rectangle482: {},
        "Group Rectangle481": {},
        Title483: {},
        ButtonBlankWhite: {},
        Rectangle486: {},
        "Group Rectangle485": {},
        Title487: {},
        ButtonPurple: {},
        "Frame Buttons": {},
        BlockArenaDescription: {},
      },
      variantValues: { size: "small" },
    },
    {
      overrides: {
        "Text Heading": { fontSize: "80px", lineHeight: "100px" },
        "Text Description": { fontSize: "30px", lineHeight: "45px" },
        Rectangle482: {
          width: "380px",
          height: "76px",
          top: "calc(50% - 38px - 0px)",
          left: "calc(50% - 190px - 0px)",
        },
        "Group Rectangle481": { width: "380px", height: "76px" },
        Title483: {
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "29.256000518798828px",
          width: "209px",
          height: "36.73px",
          top: "calc(50% - 18.37px - -4.37px)",
          left: "calc(50% - 104.5px - 2.5px)",
        },
        ButtonBlankWhite: { width: "380px", height: "76px" },
        Rectangle486: {
          width: "380px",
          height: "76px",
          top: "calc(50% - 38px - 0px)",
          left: "calc(50% - 190px - 0px)",
        },
        "Group Rectangle485": { width: "380px", height: "76px" },
        Title487: {
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "29.256000518798828px",
          top: "calc(50% - 14.5px - -0.5px)",
          left: "calc(50% - 111px - -4px)",
        },
        ButtonPurple: { width: "380px", height: "76px" },
        "Frame Buttons": {
          gap: "75px",
          direction: "row",
          padding: "25px 25px 25px 25px",
        },
        BlockArenaDescription: {
          gap: "50px",
          width: "1544px",
          padding: "50px 50px 50px 50px",
        },
      },
      variantValues: { size: "default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="25px"
      direction="column"
      width="375px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="25px 25px 25px 25px"
      backgroundColor="rgba(0,0,0,1)"
      display="flex"
      {...getOverrideProps(overrides, "BlockArenaDescription")}
      {...rest}
    >
      <Text
        fontFamily="Jost"
        fontSize="28px"
        fontWeight="500"
        color="rgba(255,255,255,1)"
        lineHeight="35px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.85px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Battling platform like you've never experienced before"
        {...getOverrideProps(overrides, "Text Heading")}
      ></Text>
      <Text
        fontFamily="Poppins"
        fontSize="12px"
        fontWeight="400"
        color="rgba(255,255,255,1)"
        lineHeight="18px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.85px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="World's best performers and creators in one place compete with each other! You don't want to miss it… Phenomenal skills, Glorious battles and New Age Tournaments happen online in ARENA!"
        {...getOverrideProps(overrides, "Text Description")}
      ></Text>
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="10px 0px 10px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame Buttons")}
      >
        <View
          width="291px"
          height="45px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children={buttonBlankWhite}
          {...getOverrideProps(overrides, "ButtonBlankWhite")}
        ></View>
        <View
          width="291px"
          height="45px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children={buttonPurple}
          {...getOverrideProps(overrides, "ButtonPurple")}
        ></View>
      </Flex>
    </Flex>
  );
}
