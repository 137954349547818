import React from 'react';
import { useState } from 'react'
import { UserInfoModelCreateForm, BlockJoinArenaForm } from '../../ui-components';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useBreakpointValue } from '@aws-amplify/ui-react';

import '../../styles/ContactUs.css';

var img_formBackground_src = '/images/FormBackgroundShort.png';

function ContactUs() {

    const searchParams = new URLSearchParams(window.location.search);

    const [showForm, setShowForm] = useState(true)
    const [isChecked, setIsChecked] = useState(true);
    const [userType, setUserType] = useState(searchParams.get('type').toUpperCase());

    const size = useBreakpointValue({
        small: 'small',
        medium: 'default'
    })

    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate('/');
    };

    const updatedFields = (fields) => {

        const updatedFields = {}

        Object.keys(fields).forEach(key => {
            if (key === 'userType') {
                updatedFields[key] = userType.toUpperCase()
            } else if (key === 'subscribed') {
                updatedFields[key] = isChecked
            } else {
                updatedFields[key] = fields[key]
            }
        })

        return updatedFields
    };

    //var contestText = "Embark on an exhilarating journey of growth at our upcoming contest. Push boundaries, sharpen abilities, and gain invaluable competitive experience. Showcase your talents, learn from experts, and connect with a vibrant community of achievers. \n\n Whether seasoned or new, our level playing field rewards determination and skill. Be challenged, inspired, and transformed into a formidable force. Don't miss this extraordinary opportunity to elevate your game and experience the thrill of competition. Join us on the journey towards greatness.";
    //var contestText = "Embark on an exhilarating journey of growth at our upcoming contest. Push boundaries, sharpen abilities, and gain invaluable competitive experience. Showcase your talents, learn from experts, and connect with a vibrant community of achievers.";
    var contestText = "Push boundaries, sharpen abilities, and gain invaluable competitive experience. Showcase your talents, learn from experts, and connect with a vibrant community of achievers. \nDon't miss this extraordinary opportunity to elevate your game and experience the thrill of competition. Join us on the journey towards greatness.";

    if (showForm) {
        return (
            <BlockJoinArenaForm
                width={'100%'}
                height={'100vh'}
                style={{ minHeight: size === 'default' ? '730px' : '800px'}}
                title='Join ARENA'
                description={contestText}//'Clarity gives you the blocks and components you need to create a truly professional website.'
                imageSrc={img_formBackground_src}
                formPlaceHoder={
                    <UserInfoModelCreateForm
                        onSuccess={() => { setShowForm(false) }}
                        onCancel={() => { navigateToHome() }}
                        onSubmit={(fields) => { return updatedFields(fields) }}
                        overrides={{
                            SubmitButton: {
                                className: 'contactus-submit-button',
                            },
                            ClearButton: {
                                className: 'contactus-clear-button',
                            },
                            CancelButton: {
                                className: 'contactus-clear-button',
                            },
                            userType: { 
                                value: userType.toUpperCase(),
                                onChange: (e) => {
                                    setUserType(e.target.value)
                                }
                            },
                            subscribed: {
                                checked: isChecked ? true : false,
                                onClick: () => {
                                    setIsChecked(!isChecked)
                                }
                            }
                        }}
                    />
                }
                size={size}
            />
        )
    }
    else {
        return (
            <BlockJoinArenaForm
                width={'100%'}
                title='You have successfully joined Arena!'
                description='The app is not yet available in your region. We will release it very soon and notify you by email. Thank you for your continued support!'
                imageSrc={img_formBackground_src}
                formPlaceHoder={
                    <div className='back-label'>Back to <Link className='back-label-link' to="/">Home</Link>!</div>
                }
            />
        )
    }
}

export default ContactUs;