/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import UserCard from "./UserCard";
export default function BlockRecommendations(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Text Heading": {},
        UserCard1: {},
        UserCard2: {},
        UserCard3: {},
        "Frame User Cards": {},
        BlockRecommendations: {},
      },
      variantValues: { size: "small" },
    },
    {
      overrides: {
        "Text Heading": { fontSize: "52px", lineHeight: "62px" },
        UserCard1: {},
        UserCard2: {},
        UserCard3: {},
        "Frame User Cards": { direction: "row" },
        BlockRecommendations: { gap: "75px", padding: "75px 50px 75px 50px" },
      },
      variantValues: { size: "default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="20px 20px 20px 20px"
      backgroundColor="rgba(0,0,0,1)"
      display="flex"
      {...getOverrideProps(overrides, "BlockRecommendations")}
      {...rest}
    >
      <Text
        fontFamily="Poppins"
        fontSize="24px"
        fontWeight="400"
        color="rgba(255,255,255,1)"
        lineHeight="30px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.85px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Recommendations from performers"
        {...getOverrideProps(overrides, "Text Heading")}
      ></Text>
      <Flex
        gap="75px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame User Cards")}
      >
        <UserCard
          width="316px"
          height="568px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "UserCard1")}
        ></UserCard>
        <UserCard
          width="316px"
          height="568px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "UserCard2")}
        ></UserCard>
        <UserCard
          width="316px"
          height="568px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "UserCard3")}
        ></UserCard>
      </Flex>
    </Flex>
  );
}
