import './App.css';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import Home from './components/pages/Home';
import ContactUs from './components/pages/ContactUs';
import NotFound from './components/pages/NotFound';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>          
          <Route path="/" element={<Home />} />      
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
