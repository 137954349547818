/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import DescriptionCard from "./DescriptionCard";
export default function BlockWhyArena(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Text Heading": {},
        DescriptionCard1: {},
        DescriptionCard2: {},
        "Column 1": {},
        DescriptionCard3: {},
        DescriptionCard4: {},
        "Column 2": {},
        "Frame Cards": {},
        BlockWhyArena: {},
      },
      variantValues: { size: "small" },
    },
    {
      overrides: {
        "Text Heading": { fontSize: "52px" },
        DescriptionCard1: { size: "default" },
        DescriptionCard2: { size: "default" },
        "Column 1": {},
        DescriptionCard3: { size: "default" },
        DescriptionCard4: { size: "default" },
        "Column 2": {},
        "Frame Cards": { direction: "row", padding: "0px 0px 0px 0px" },
        BlockWhyArena: { gap: "50px", padding: "50px 50px 50px 50px" },
      },
      variantValues: { size: "default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="20px 0px 20px 0px"
      backgroundColor="rgba(31,29,29,1)"
      display="flex"
      {...getOverrideProps(overrides, "BlockWhyArena")}
      {...rest}
    >
      <Text
        fontFamily="Poppins"
        fontSize="24px"
        fontWeight="600"
        color="rgba(255,255,255,1)"
        lineHeight="62px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.85px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Why Arena"
        {...getOverrideProps(overrides, "Text Heading")}
      ></Text>
      <Flex
        gap="25px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 5px 0px 5px"
        display="flex"
        {...getOverrideProps(overrides, "Frame Cards")}
      >
        <Flex
          gap="25px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Column 1")}
        >
          <DescriptionCard
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            {...getOverrideProps(overrides, "DescriptionCard1")}
          ></DescriptionCard>
          <DescriptionCard
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            {...getOverrideProps(overrides, "DescriptionCard2")}
          ></DescriptionCard>
        </Flex>
        <Flex
          gap="25px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Column 2")}
        >
          <DescriptionCard
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            {...getOverrideProps(overrides, "DescriptionCard3")}
          ></DescriptionCard>
          <DescriptionCard
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            {...getOverrideProps(overrides, "DescriptionCard4")}
          ></DescriptionCard>
        </Flex>
      </Flex>
    </Flex>
  );
}
