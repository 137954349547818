import React, { useState } from 'react';

import '../../styles/PlayButton.css';

const PlayButton = ({ soundSrc, imagePlaySrc, imagePauseSrc }) => {

    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = React.createRef();

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        imagePlaySrc ?
            <div className='play-button'>
                <div onClick={togglePlay}>
                    <img src={isPlaying ? imagePauseSrc : imagePlaySrc} alt={isPlaying ? 'Pause' : 'Play'} />
                </div>
                <audio ref={audioRef} src={soundSrc} />
            </div>
            : null
    );
};

export default PlayButton;
