/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, View } from "@aws-amplify/ui-react";
import UserTitle from "./UserTitle";
export default function UserCard(props) {
  const { imageSrc, playIconContainer, overrides, ...rest } = props;
  return (
    <View
      width="316px"
      height="568px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "UserCard")}
      {...rest}
    >
      <Image
        width="100%"
        height="100%"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src={imageSrc}
        {...getOverrideProps(overrides, "Image User")}
      ></Image>
      <Flex
        gap="50px"
        direction="row"
        width="316px"
        height="568px"
        justifyContent="center"
        alignItems="flex-end"
        overflow="hidden"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="25px 25px 25px 25px"
        {...getOverrideProps(overrides, "Frame Titles")}
      >
        <UserTitle
          display="flex"
          gap="0"
          direction="column"
          width="185px"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "UserTitle")}
        ></UserTitle>
        <Flex
          padding="0px 0px 0px 0px"
          width="58px"
          height="58px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          children={playIconContainer}
          {...getOverrideProps(overrides, "PlayIconContainer")}
        ></Flex>
      </Flex>
    </View>
  );
}
