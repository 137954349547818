/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import Logo from "./Logo";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import IconHamburger from "./IconHamburger";
export default function Header(props) {
  const { buttonGetStarted, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Logo: {},
        Rectangle4358: {},
        "Group Rectangle4357": {},
        ProductTitle: {},
        ButtonProduct: {},
        Rectangle4362: {},
        "Group Rectangle4361": {},
        WhyArenaTitle: {},
        ButtonPerformers: {},
        Rectangle4366: {},
        "Group Rectangle4365": {},
        Title: {},
        ButtonGetStarted: {},
        IconHamburger: {},
        "Frame Buttons": {},
        Header: {},
      },
      variantValues: { size: "small" },
    },
    {
      overrides: {
        Logo: {},
        Rectangle4358: { left: "calc(50% - 88px - -88px)" },
        "Group Rectangle4357": {},
        ProductTitle: {
          fontSize: "18px",
          lineHeight: "21.941999435424805px",
          top: "calc(50% - 12.08px - -0.08px)",
          left: "calc(50% - 61.31px - -0.31px)",
        },
        ButtonProduct: { width: "176px" },
        Rectangle4362: { left: "calc(50% - 88px - -88px)" },
        "Group Rectangle4361": {},
        WhyArenaTitle: {
          fontSize: "18px",
          lineHeight: "21.941999435424805px",
          top: "calc(50% - 12.08px - -0.08px)",
          left: "calc(50% - 61.31px - -0.31px)",
          children: "Why Arena",
        },
        ButtonPerformers: { width: "176px" },
        Rectangle4366: {},
        "Group Rectangle4365": {},
        Title: {
          fontSize: "18px",
          lineHeight: "21.941999435424805px",
          width: "122.61px",
          height: "24.17px",
          top: "calc(50% - 12.09px - -0.09px)",
          left: "calc(50% - 61.31px - -0.31px)",
        },
        ButtonGetStarted: { display: "block" },
        IconHamburger: {},
        "Frame Buttons": {},
        Header: {
          width: "1544px",
          height: "130px",
          padding: "0px 0px 0px 0px",
        },
      },
      variantValues: { size: "default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="row"
      width="587px"
      height="58px"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 5px 0px 5px"
      backgroundColor="rgba(87,17,112,1)"
      display="flex"
      {...getOverrideProps(overrides, "Header")}
      {...rest}
    >
      <Logo
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        size="default"
        {...getOverrideProps(overrides, "Logo")}
      ></Logo>
      <Flex
        gap="15px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="10px 20px 10px 20px"
        display="flex"
        {...getOverrideProps(overrides, "Frame Buttons")}
      >
        <View
          width="100px"
          height="50px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ButtonProduct")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="0px"
            height="0px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="100%"
            left="0%"
            right="100%"
            {...getOverrideProps(overrides, "Group Rectangle4357")}
          >
            <View
              width="176px"
              height="50px"
              display="none"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="calc(50% - 25px - -25px)"
              left="calc(50% - 88px - -50px)"
              border="3px SOLID rgba(87,17,112,1)"
              borderRadius="60px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Rectangle4358")}
            ></View>
          </View>
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="500"
            color="rgba(255,217,217,1)"
            textTransform="capitalize"
            lineHeight="17.06599998474121px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="122.61px"
            height="24.17px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="calc(50% - 12.08px - -2.87px)"
            left="calc(50% - 61.31px - 1.47px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Product"
            {...getOverrideProps(overrides, "ProductTitle")}
          ></Text>
        </View>
        <View
          width="100px"
          height="50px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ButtonPerformers")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="0px"
            height="0px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="100%"
            left="0%"
            right="100%"
            {...getOverrideProps(overrides, "Group Rectangle4361")}
          >
            <View
              width="176px"
              height="50px"
              display="none"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="calc(50% - 25px - -25px)"
              left="calc(50% - 88px - -50px)"
              border="3px SOLID rgba(87,17,112,1)"
              borderRadius="60px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Rectangle4362")}
            ></View>
          </View>
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="500"
            color="rgba(255,217,217,1)"
            textTransform="capitalize"
            lineHeight="17.06599998474121px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="122.61px"
            height="24.17px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="calc(50% - 12.08px - -2.87px)"
            left="calc(50% - 61.31px - 1.47px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Performers"
            {...getOverrideProps(overrides, "WhyArenaTitle")}
          ></Text>
        </View>
        <View
          width="176px"
          height="50px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children={buttonGetStarted}
          {...getOverrideProps(overrides, "ButtonGetStarted")}
        ></View>
        <IconHamburger
          width="40px"
          height="40px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IconHamburger")}
        ></IconHamburger>
      </Flex>
    </Flex>
  );
}
