import React from 'react';

import {
    BlockAboutArena,
    BlockArenaDescription,
    BlockForPerformers,
    BlockRecommendations,
    BlockWhatOurUsersTell,
    BlockWhyArena,
    BlockWhyArenaAdditional,
    Footer,
    Header
} from '../../ui-components';

import TransparentButton from '../common/TransparentButton'
import PurpleButton from '../common/PurpleButton'
import SmallTransparentButton from '../common/SmallTransparentButton.js';
import SmallPurpleButton from '../common/SmallPurpleButton';
import HeaderPurpleButton from '../common/HeaderPurpleButton.js';
import ScrollToTop from '../common/ScrollToTop.js';

import { useBreakpointValue } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

import { getUrl } from 'aws-amplify/storage';

import { useState, useEffect } from 'react';

import PlayButton from '../common/PlayButton'

function Home() {

    const fetchContentUrl = async (contentPath, setterFunc) => {
        try {
            const getUrlResult = await getUrl({
                path: contentPath
            });
            setterFunc(getUrlResult.url);
        } catch (error) {
            console.error('An error occurred while fetching content URL: ', error);
        }
    };

    const size = useBreakpointValue({
        small: 'small',
        medium: 'default'
    })

    const navigate = useNavigate();

    const navigateToContactUs = (from) => {
        navigate('/contactUs?type=' + from);
    };

    function scrollToAnchor(anchorId) {

        const anchor = document.getElementById(anchorId);

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', speed: 12 });
        }
    }

    const [imgLogoSrc, setImgLogoSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/images/logo.png', setImgLogoSrc); return () => { }; }, []);

    const [videoAboutSrc, setVideoAboutSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/videos/ArenaDescription.mp4', setVideoAboutSrc); return () => { }; }, []);

    const [videoDemoSrc, setVideoDemoSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/videos/ArenaDemo.mp4', setVideoDemoSrc); return () => { }; }, []);

    const [imgStarSrc, setImgStarSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/images/Star.png', setImgStarSrc); return () => { }; }, []);

    const [imgCardBackgroundSrc, setImgCardBackgroundSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/images/CardBackground.png', setImgCardBackgroundSrc); return () => { }; }, []);

    // const [imgPlayIconSrc, setImgPlayIconSrc] = useState('');
    // useEffect(() => { fetchContentUrl('public/images/PlayIcon.png', setImgPlayIconSrc); return () => { }; }, []);

    const [imgIconHanburgerSrc, setImgIconHanburgerSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/images/IconHamburger.png', setImgIconHanburgerSrc); return () => { }; }, []);


    const [imgCard1Src, setImgCard1Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/Card1.png', setImgCard1Src); return () => { }; }, []);

    const [imgCard2Src, setImgCard2Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/Card2.png', setImgCard2Src); return () => { }; }, []);


    const [imgCard3Src, setImgCard3Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/Card3.png', setImgCard3Src); return () => { }; }, []);

    const [imgCard4Src, setImgCard4Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/Card4.png', setImgCard4Src); return () => { }; }, []);

    const [imgVideo1Src, setImgVideo1Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/Video1.png', setImgVideo1Src); return () => { }; }, []);

    const [imgVideo2Src, setImgVideo2Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/Video2.png', setImgVideo2Src); return () => { }; }, []);


    const [imgRectangularUserFoto1Src, setImgRectangularUserFoto1Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/User11.png', setImgRectangularUserFoto1Src); return () => { }; }, []);

    const [imgRectangularUserFoto2Src, setImgRectangularUserFoto2Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/User12.png', setImgRectangularUserFoto2Src); return () => { }; }, []);

    const [imgRectangularUserFoto3Src, setImgRectangularUserFoto3Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/User13.png', setImgRectangularUserFoto3Src); return () => { }; }, []);


    const [imgSquareUserFoto1Src, setImgSquareUserFoto1Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/User21.png', setImgSquareUserFoto1Src); return () => { }; }, []);

    const [imgSquareUserFoto2Src, setImgSquareUserFoto2Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/User22.png', setImgSquareUserFoto2Src); return () => { }; }, []);

    const [imgSquareUserFoto3Src, setimgSquareUserFoto3Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/User23.png', setimgSquareUserFoto3Src); return () => { }; }, []);


    const [imgUsersFotoSrc, setImgUsersFotoSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/images/Users.png', setImgUsersFotoSrc); return () => { }; }, []);


    const [sound1SrcSrc, setSound1SrcSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/audios/Perseus-Male.mp3', setSound1SrcSrc); return () => { }; }, []);

    const [sound3SrcSrc, setSound2SrcSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/audios/Asteria-Female.mp3', setSound2SrcSrc); return () => { }; }, []);

    const [sound2SrcSrc, setSound3SrcSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/audios/Orion-Male.mp3', setSound3SrcSrc); return () => { }; }, []);


    const [imagePlaySrc, setImagePlaySrc] = useState('');
    useEffect(() => { fetchContentUrl('public/images/primitives/PlayIcon-play.png', setImagePlaySrc); return () => { }; }, []);

    const [imagePauseSrc, setImagePauseSrc] = useState('');
    useEffect(() => { fetchContentUrl('public/images/primitives/PlayIcon-pause.png', setImagePauseSrc); return () => { }; }, []);

    const [imageBlur1Src, setImageBlur1Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/backgrounds/blur1.png', setImageBlur1Src); return () => { }; }, []);

    const [imageBlur2Src, setImageBlur2Src] = useState('');
    useEffect(() => { fetchContentUrl('public/images/backgrounds/blur2.png', setImageBlur2Src); return () => { }; }, []);



    return (
        <div style={{ minWidth: size === 'default' ? '1300px' : '375px'}}>            
            <ScrollToTop />
            <Header                
                overrides={{
                    Logo: {
                        imageSrc: imgLogoSrc,
                        size: size
                    },
                    IconHamburger: { imageSrc: imgIconHanburgerSrc },
                    ProductTitle: {
                        onClick: () => scrollToAnchor("about-arena-section"),
                        style: {
                            cursor: 'pointer'
                        }
                    },
                    WhyArenaTitle: {
                        onClick: () => scrollToAnchor("why-arena-section"),
                        style: {
                            cursor: 'pointer'
                        }
                    }
                }}
                size={size}
                width={'100%'}
                buttonGetStarted={
                    <HeaderPurpleButton
                        children='Get Started'
                        onClick={() => navigateToContactUs("watcher")}
                        size={size}
                    />
                }
            />
            <BlockArenaDescription
                buttonBlankWhite={
                    <TransparentButton
                        children='Join the App'
                        onClick={() => navigateToContactUs("watcher")}
                        size={size}
                    />
                }
                buttonPurple={
                    <PurpleButton
                        children='Join as Performer'
                        onClick={() => navigateToContactUs("performer")}
                        size={size}
                    />
                }
                size={size}
                width={'100%'}
            />
            <a id="about-arena-section"></a>
            <BlockAboutArena
                imageSrc={imgVideo1Src}
                size={size}
                width={'100%'}
                videoContainer={
                    videoAboutSrc ?
                        size === 'default' ?
                            <video width="1280" height="720" controls>
                                <source src={videoAboutSrc} type="video/mp4" />
                            </video>
                            :
                            <video width="365" height="240" controls>
                                <source src={videoAboutSrc} type="video/mp4" />
                            </video>
                        :
                        <div>Loading...</div>
                }
            />
            <a id="why-arena-section"></a>
            <BlockWhyArena
                style={{ 
                    backgroundImage: `url("${imageBlur1Src}")`,
                    backgroundSize: 'cover'
                }}
                overrides={{
                    DescriptionCard1: { imageSrc: imgCard3Src, text: 'Find out who is the best talent!!! The best compete here and you must see it!' },
                    DescriptionCard2: { imageSrc: imgCard2Src, text: 'Vote for the performers you like and help them win!' },
                    DescriptionCard3: { imageSrc: imgCard1Src, text: 'Enjoy the most competitive content you can get! Battles, skills, tournaments… all you need is here - in Arena!' },
                    DescriptionCard4: { imageSrc: imgCard4Src, text: "It's incredibly easy to join the amazing competitive environment where all the best-known performers from all over the world showcasing their skills, take part in battles and new age tournaments online" }
                }}
                size={size}
                width={'100%'}
            />
            <BlockWhatOurUsersTell
                overrides={{
                    UserFeedbackCard6627: {
                        imageSrc: imgSquareUserFoto1Src,
                        feedbackText: "\"Arena works like magic! Unveiling the best among the best has never been more thrilling! Where else can you witness this level of competition? Prada taking on Louis Vuitton, Balenciaga challenging Givenchy. I'm completely captivated and hungry for more!\"",
                        overrides: {
                            UserTitleBlack: { userName: 'Darlene Robertson', userProfession: 'Footballer' },
                            RatingBlack: {
                                overrides: {
                                    Star1: { imageSrc: imgStarSrc },
                                    Star2: { imageSrc: imgStarSrc },
                                    Star3: { imageSrc: imgStarSrc },
                                    Star4: { imageSrc: imgStarSrc },
                                    Star5: { imageSrc: imgStarSrc }
                                },
                                rating: '4.8/5'
                            }
                        }
                    },
                    UserFeedbackCard6647: {
                        imageSrc: imgSquareUserFoto2Src, feedbackText:
                            "\"Arena is simply amazing. The product allows to find out who is the best between the greatest! Where else can I see this?! Messi vs Ronaldo, Jordan vs Lebron. I'm super excited and want more!\"",
                        overrides: {
                            UserTitleBlack: { userName: 'Bessie Cooper', userProfession: 'Freelance Designer' },
                            RatingBlack: {
                                overrides: {
                                    Star1: { imageSrc: imgStarSrc },
                                    Star2: { imageSrc: imgStarSrc },
                                    Star3: { imageSrc: imgStarSrc },
                                    Star4: { imageSrc: imgStarSrc },
                                    Star5: { imageSrc: imgStarSrc }
                                },
                                rating: '4.7/5'
                            }
                        }
                    },
                    UserFeedbackCard6667: {
                        imageSrc: imgSquareUserFoto3Src,
                        feedbackText: "\"It's just woow!! I tell you what, why don't the politicians have their debates right here! It's made for it! Really nice app! Thanks to everyone who made it happen!\"",
                        overrides: {
                            UserTitleBlack: { userName: 'Arlene McCoy', userProfession: 'Product Manager' },
                            RatingBlack: {
                                overrides: {
                                    Star1: { imageSrc: imgStarSrc },
                                    Star2: { imageSrc: imgStarSrc },
                                    Star3: { imageSrc: imgStarSrc },
                                    Star4: { imageSrc: imgStarSrc },
                                    Star5: { imageSrc: imgStarSrc }
                                },
                                rating: '4.4/5'
                            }
                        }
                    },
                }}
                size={size}
                width={'100%'}
            />
            <BlockWhyArenaAdditional
                style={{ 
                    backgroundImage: `url("${imageBlur2Src}")`,
                    backgroundSize: 'cover'
                }}
                imageUsersSrc={imgUsersFotoSrc}
                overrides={{
                    InfoCard1: { imageSrc: imgCardBackgroundSrc, text: 'Performers get ad revenue from its content views (battles & skills) on the platform, and by collecting donations' },
                    InfoCard2: { imageSrc: imgCardBackgroundSrc, text: 'Arena community where performers learn the best hacks and share the best winning practices' },
                    InfoCard3: { imageSrc: imgCardBackgroundSrc, text: 'Tutorial and customer support' },
                    InfoCard4: { imageSrc: imgCardBackgroundSrc, text: 'Smart filters to beautify performers and their content' },
                    Logo: { imageSrc: imgLogoSrc },
                    InfoCard6: { imageSrc: imgCardBackgroundSrc, text: 'AI tools to help diversify content' },
                    InfoCard7: { imageSrc: imgCardBackgroundSrc, text: 'Arena motivates creators to collaborate with other performers and re-use their most popular content' },
                    InfoCard8: { imageSrc: imgCardBackgroundSrc, text: 'Platform supports video and non-video content' },
                    InfoCard9: { imageSrc: imgCardBackgroundSrc, text: 'Arena offers great variety of payout options for any performing creator around the world' },
                }}
                size={size}
                width={'100%'}
            />
            {/* <BlockForPerformers
                videoContainer={                    
                    videoDemoSrc ?
                        size === 'default' ?
                            <video width="auto" height="720" controls>
                                <source src={videoDemoSrc} type="video/mp4" />
                            </video>
                            :
                            <video width="420" height="260" controls>
                                <source src={videoDemoSrc} type="video/mp4" />
                            </video>
                        :
                        <div>Loading...</div>
                }
                buttonPurple={
                    <PurpleButton
                        children='Join as Performer'
                        onClick={() => navigateToContactUs("performer")}
                        size={size}
                    />
                }
                size={size}
                width={'100%'}                
            /> */}
            <BlockRecommendations
                overrides={{
                    UserCard1: {
                        imageSrc: imgRectangularUserFoto1Src,
                        playIconContainer: (
                            <PlayButton soundSrc={sound1SrcSrc} imagePlaySrc={imagePlaySrc} imagePauseSrc={imagePauseSrc} />
                        ),
                        overrides: {
                            PlayIcon: { imageSrc: imagePlaySrc },
                            UserTitle: { userName: 'Dwight Green', userProfession: 'Rap Musician & Producer' }
                        }
                    },
                    UserCard2: {
                        imageSrc: imgRectangularUserFoto2Src,
                        playIconContainer: (
                            <PlayButton soundSrc={sound3SrcSrc} imagePlaySrc={imagePlaySrc} imagePauseSrc={imagePauseSrc} />
                        ),
                        overrides: {
                            PlayIcon: { imageSrc: imagePlaySrc },
                            UserTitle: { userName: 'Karen Phillips', userProfession: 'Dancer & Nutrionist' }
                        }
                    },
                    UserCard3: {
                        imageSrc: imgRectangularUserFoto3Src,
                        playIconContainer: (
                            <PlayButton soundSrc={sound2SrcSrc} imagePlaySrc={imagePlaySrc} imagePauseSrc={imagePauseSrc} />
                        ),
                        overrides: {
                            PlayIcon: { imageSrc: imagePlaySrc },
                            UserTitle: { userName: 'Arnold Henry', userProfession: 'Rock’n’Roll Star' }
                        }
                    }
                }}
                size={size}
                width={'100%'}
            />            
            <Footer
                overrides={{
                    Logo: { imageSrc: imgLogoSrc },
                    ProductTitle: {
                        onClick: () => scrollToAnchor("about-arena-section"),
                        style: {
                            cursor: 'pointer'
                        }
                    },
                    WhyArenaTitle: {
                        onClick: () => scrollToAnchor("why-arena-section"),
                        style: {
                            cursor: 'pointer'
                        }
                    }
                }}
                buttonJoinTheApp={
                    <SmallTransparentButton
                        children='Join the App'
                        onClick={() => navigateToContactUs("watcher")}
                        size={size}
                    />
                }
                buttonJoinAsPerformer={
                    <SmallPurpleButton
                        children='Join as Performer'
                        onClick={() => navigateToContactUs("performer")}
                        size={size}
                    />
                }
                size={size}
                width={'100%'}
            />
        </div>
    );
}

export default Home;

