import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/PurpleButton.css';

const PurpleButton = ({ size, children, onClick }) => {

    const buttonSizeClass = size === 'default' ? 'purple-button-default' : 'purple-button-small';

    return (
        <button className={"purple-button" + " " + buttonSizeClass} onClick={onClick}>
            {children}
        </button>
    );
};

PurpleButton.propTypes = {
    size: PropTypes.oneOf(['default', 'small']),
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired 
};

PurpleButton.defaultProps = {
    size: 'default' 
};

export default PurpleButton;
