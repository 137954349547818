/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function BlockAboutArena(props) {
  const { imageSrc, videoContainer, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Text Heading": {},
        Video: {},
        VideoContainer: {},
        BlockAboutArena: {},
      },
      variantValues: { size: "small" },
    },
    {
      overrides: {
        "Text Heading": { fontSize: "52px" },
        Video: { width: "1280px", height: "720px" },
        VideoContainer: { height: "720px" },
        BlockAboutArena: {
          gap: "50px",
          width: "1380px",
          padding: "50px 50px 50px 50px",
        },
      },
      variantValues: { size: "default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="375px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="10px 5px 10px 5px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "BlockAboutArena")}
      {...rest}
    >
      <Text
        fontFamily="Poppins"
        fontSize="24px"
        fontWeight="600"
        color="rgba(9,9,20,1)"
        lineHeight="62px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.85px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="About Arena"
        {...getOverrideProps(overrides, "Text Heading")}
      ></Text>
      <Flex
        padding="0px 0px 0px 0px"
        width="unset"
        height="239px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        children={videoContainer}
        {...getOverrideProps(overrides, "VideoContainer")}
      ></Flex>
    </Flex>
  );
}
